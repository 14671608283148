import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { Pipe } from '../pipe'

const Pi = () => {
	return (
		<L10nProvider>
			<Pipe />
		</L10nProvider>
	)
}

export default Pi;